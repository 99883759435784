//CSS library
import '../scss/library/bootstrap/bootstrap-grid.min.css';
import '../scss/library/swiper/swiper.min.css';
import '../scss/library/datepicker/datepicker.scss';
import '../scss/library/fullcalendar/main.css';
//CSS
import '../scss/style.scss';

//jQuery
// const jQuery = require('jquery');
// const $ = jQuery;

//library
//import './library/jquery.tile.min.js';
//import './library/bootstrap/bootstrap.min.js';

import {
  //bodyfadeIn,
  clickTrigger,
  loadFunc,
  resize_loadFunc,
  scrollFunc,
} from "./common.js";

//import { datepickerFunc } from "./datepicker.js";
//import { fullcalendarFunc } from "./fullcalendar.js";
//import { swiperFunc } from "./swiper.js";


//bodyfadeIn();
clickTrigger();
loadFunc();
resize_loadFunc();
scrollFunc();

//datepickerFunc();
//fullcalendarFunc();
//swiperFunc();
