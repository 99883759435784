//jQuery
const jQuery = require('jquery');
const $ = jQuery;
const imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin($);
let publicPath = {};
let imgPath = {};
publicPath = '/';
imgPath = publicPath + 'assets/img';

//variable
var page_url = location.host;
var w = $(window).width();
var h = $(window).height();
var y = $(this).scrollTop();
var breakpoint = 767;
var breakpoint_ipad = 991;
var beginscrollTop = {};

//ios
const ua = window.navigator.userAgent.toLowerCase();
const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

//=======================================================
//common function
//=======================================================
  //ブラウザサイズ sp 判定
  var sp_window = function () {
    if (!window.innerWidth) {
      return false;
    }
    if (!window.matchMedia) {
      return (window.innerWidth <= breakpoint_ipad) ? true : false;
    }
    if (window.matchMedia('(max-width:' + breakpoint_ipad + 'px)').matches) {
      return true;
    } else {
      return false;
    }
  }

//tile js
function tileList($element, $leaight) {
  if (sp_window()) {
    $($element).css('height', 'inherit');
    //console.log('SP');
  } else {
    $($element).tile($leaight);
    //console.log('PC');
  }
}

//スクロール固定
const beginScroll = function () {
  if (isiOS) {
    beginscrollTop = $(window).scrollTop();
    $('body').addClass('is-no-scroll').css('top', (-beginscrollTop) + 'px');
  } else {
    $('body').css('overflow', 'hidden');
  }
};
const endScroll = function () {
  if (isiOS) {
    $('body').removeClass('is-no-scroll').css('top', '');
    $(window).scrollTop(beginscrollTop);
  } else {
    $('body').css('overflow', '');
  }
};

//=======================================================
//click function
//=======================================================
export function clickTrigger() {

  $('.is-menu-trigger').on('click', function () {
    if ($(this).hasClass('is-active')){
      $(this).removeClass('is-active');
      $(this).nextAll('.p-nav').removeClass('is-active');
      endScroll();
      console.log('no');
    } else {
        $(this).addClass('is-active');
        $(this).nextAll('.p-nav').addClass('is-active');
        beginScroll();
        console.log('ok');
    }
    return false;
  });

  $('.is-btn-close').on('click', function () {
    $(this).closest('.p-attention').slideUp(100);
    return false;
  });

  $('.is-btn-open').on('click', function () {
    $(this).toggleClass('is-active');
    $(this).closest('.p-checkbox-list').nextAll('.p-checkbox-list-hidden-1').slideToggle('fast');
    $(this).closest('.p-checkbox-list').nextAll('.p-checkbox-list-hidden-2').slideToggle('fast');
    return false;
  });

  //checkbox
  $('.is-parent-checkbox').change(function () {

    const checked = $(this).prop('checked');
    const openbtn1 = $(this).closest('label').next('.is-btn-open');

    if (checked) {
      openbtn1.hide().removeClass('is-active');
      //子要素
      const child1 = $(this).closest('.p-checkbox-list').nextAll('.p-checkbox-list-hidden-1');
      const child2 = $(this).closest('.p-checkbox-list').nextAll('.p-checkbox-list-hidden-1').find('.p-checkbox-list-hidden-2');
      //子要素チェックボックスを外す
      const childcheckd = child1.find('input[type="checkbox"]');
      childcheckd.prop('checked', false);
      child1.find('.is-btn-open').show();
      //子要素判定
      child1.slideUp('fast');
      $('.p-checkbox-list-hidden-1 .is-btn-open').removeClass('is-active').show();
      // if (child1.is(':visible')) {
      //   //console.log('child1は表示');
      // }
      child2.slideUp('fast');
      // if (child2.is(':visible')) {
      //   //console.log('child2は表示');
      // }
      //console.log('OK');
    } else {
      openbtn1.show();
      //console.log('NO');
    }
  });
  //子要素のチェック
  $('.is-child1-checkbox').change(function () {
    const checked = $(this).prop('checked');
    const openbtn1 = $(this).closest('label').nextAll('.is-btn-open');

    if (checked) {
      const parentcheck = $(this).closest('.p-checkbox-list-hidden-1').prev('p-checkbox-list').find('.is-parent-checkbox');
      parentcheck.prop('checked', false);
      openbtn1.hide();
      //子要素
      const child1 = $(this).closest('.p-checkbox-list').nextAll('.p-checkbox-list-hidden-2');
      if (child1.is(':visible')) {
        child1.slideUp('fast');
        openbtn1.removeClass('is-active');
        //子要素チェックボックスを外す
        const childcheckd = child1.find('input[type="checkbox"]');
        childcheckd.prop('checked', false);
      }
    } else {
      openbtn1.show();
    }
  });

  $('.is-checkbox-allclear').on('click', function () {
    const nextel = $(this).closest('.p-search-modal-subtitle').next();
    const nexte2 = $(this).closest('.p-search-modal-title').next();
    const checkbox = nextel.find('input[type="checkbox"]');
    const checkbox2 = nexte2.find('input[type="checkbox"]');
    const openbtn = nextel.find('.is-btn-open');
    const openslide1 = nextel.find('.p-checkbox-list-hidden-1');
    const openslide2 = nextel.find('.p-checkbox-list-hidden-2');

    if ($(this).hasClass('is-allclear-title')) {
      checkbox2.prop('checked', false);
      if (nexte2.find('.is-btn-open').length) {
        nexte2.find('.is-btn-open').removeClass('is-active').show();
        nexte2.find('.p-checkbox-list-hidden-1').hide();
      }
    } else {
      checkbox.prop('checked', false);
      openbtn.removeClass('is-active').show();
      openslide1.add(openslide2).hide();
    }
    return false;
  });

    $('.is-detail-search-show').on('click', function () {
      const href = $(this).data('id');
      $('.is-search-modal-mask').show();
      beginScroll();
      $('#' + href).show().scrollTop(0);
        setTimeout(function () {
          $('.p-search-modal .p-search-modal-button').addClass('is-show');
        }, 300);
      return false;
    });

  $('.p-search-modal .is-btn-close').add('.p-search-modal .is-modal-close').add('.is-search-modal-mask').on('click', function () {
    if ($('.is-page-search-box').length) {
      let txt = [];
      let id = [];
      let check = [];
      let modalTitle = {};
      if ($(this).hasClass('is-btn-close')) {
        modalTitle = $(this).prev('h2').text();
      } else if ($(this).hasClass('is-search-modal-mask')) {
        modalTitle = $('.p-search-modal:visible').find('.p-search-modal-title').find('h2').text();
      } else {
        modalTitle = $(this).parent().prevAll('.p-search-modal-title').find('h2').text();
      }
      //
      if ($(this).hasClass('is-search-modal-mask')) {
        id = $('.p-search-modal:visible').attr('id');
        check = $('.p-search-modal:visible').find('input:checkbox:checked').length;
        $('.p-search-modal:visible').find('input:checkbox:checked').parents('.c-form-chexbox-box').next().each(function () {
          txt.push($(this).text());
        });
      } else {
        id = $(this).parents('.p-search-modal').attr('id');
        check = $(this).parents('.p-search-modal').find('input:checkbox:checked').length;
        $(this).parents('.p-search-modal').find('input:checkbox:checked').parents('.c-form-chexbox-box').next().each(function () {
          txt.push($(this).text());
        });
      }

      const changeTxt = $('[data-id="'+id+'"]').find('.is-change-txt')
      //console.log(id, check, txt);

      if (2 <= check) {
        changeTxt.text('複数選択されてます');
        } else if (1 == check) {
          changeTxt.text(txt);
        } else {
          changeTxt.text(modalTitle);
      }
    }
    endScroll();
    $('.p-search-modal:visible').hide();
    $('.is-search-modal-mask').hide();
    $('.p-search-modal .p-search-modal-button').removeClass('is-show');
    return false;
  });

  $('.is-backtotop').on('click', function () {
    $('html,body').animate({
      scrollTop: 0
    }, 500, 'swing');
    return false;
  });


  let ua = navigator.userAgent;
  if ((ua.indexOf('Android') > 0) && ua.indexOf('Mobile') > 0 || ua.indexOf('Android') > 0) {
    $('.p-search-modal input.c-form-textbox').focusin(function (e) {
      $(this).closest('.p-search-modal').css('overflow', 'hidden');
    }).focusout(function(e) {
      $(this).closest('.p-search-modal').css('overflow', '');
    });
  }

  //
  $('.is-icon-heart-box').on('click', function () {
    let icon = $(this).find('.p-icon-list-heart');
    if (icon.hasClass('is-active')) {
      icon.removeClass('is-active').removeClass('icon-heart');
      icon.addClass('icon-heart-o');
    } else {
      icon.removeClass('icon-heart-o');
      icon.addClass('is-active').addClass('icon-heart');
    }
    return false;
  });


}

//=======================================================
// load function
//=======================================================

export function loadFunc() {
  //webp非対応
  document.documentElement.classList.remove("no-js");

  let y = $(window).scrollTop();

  //header scroll
  if ($('.p-header').hasClass('p-home')) {
    if (y > 50) {
      $('.p-header').addClass('is-header-scroll');
      $('.is-menu-trigger').addClass('is-menu-trigger-scroll');
      $('header .p-h1').find('img').attr('src', imgPath + '/logo-black.svg');
      $('header .p-header-icon li').eq(0).find('img').attr('src', imgPath +'/icon-search-black.svg');
      $('header .p-header-icon li').eq(1).find('img').attr('src', imgPath + '/icon-login-black.svg');
      $('header .p-header-icon li').eq(2).find('img').attr('src', imgPath + '/icon-group-black.svg');
      if (!sp_window()) {
        $('.p-global').addClass('is-scroll-global');
      }
    } else {
      $('.p-header').removeClass('is-header-scroll');
      $('.is-menu-trigger').removeClass('is-menu-trigger-scroll');
      $('header .p-h1').find('img').attr('src', imgPath + '/logo-white.svg');
      $('header .p-header-icon li').eq(0).find('img').attr('src', imgPath + '/icon-search.svg');
      $('header .p-header-icon li').eq(1).find('img').attr('src', imgPath + '/icon-login.svg');
      $('header .p-header-icon li').eq(2).find('img').attr('src', imgPath + '/icon-group.svg');
      if (!sp_window()) {
        $('.p-global').removeClass('is-scroll-global');
      }
    }
  }


}
//=======================================================
//resize load function
//=======================================================
export function resize_loadFunc() {
  $(window).on("resize load", function () {
    //w = $(window).width();
    //h = $(window).height();
    //
    // if (!sp_window()) {
    //   if ($('.p-nav').css('display') == 'none') {
    //     $('.p-nav').css('display', 'block');
    //     $('.is-menu-trigger').removeClass('is-active');
    //   }
    // } else {
    //   if ($('.p-nav').css('display') == 'block') {
    //     $('.p-nav').css('display', 'none');
    //   }
    // }

    if ($('.is-list-scroll').length) {
      if (!sp_window()) {
        $('.is-list-scroll').removeClass('is-fixed');
        $('.p-list-detail').css('margin-top', '');
      }
    }

  });

  //facebook
  let first_width = $(window).width();
  let timer = false;
  $(window).resize(function () {
    if ($('.p-page-main-search--sidebar').length) {
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        //resize完了時の動作
        let width = $(window).width();
        if (width != first_width) {
          //console.log('resized');
          fbiframe_reload();
          first_width=width;
        }
      }, 200);

    }
  });

  function fbiframe_reload(){
    let width = $(".p-page-main-search--sidebar").outerWidth();//親要素のID,class要変更
    width = Math.floor(width);
    let src = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdigientry&tabs=timeline&width="+width+"&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true"

    $("#fbiframe").attr("src", "");
    $("#fbiframe").attr("src",src);
    $("#fbiframe").attr("width",width);
  };
  if ($('.p-page-main-search--sidebar').length) {
    fbiframe_reload();
  }

  // $('img').imagesLoaded( function() {
  // });
}
//=======================================================
//scroll function
//=======================================================
export function scrollFunc() {
  //list page
  if ($('.is-list-scroll').length) {
    var ele = $('.is-list-scroll');
    var eleTop = ele.offset().top;
    var eleHeight = $('.is-list-scroll').outerHeight();
    var headerHeight = $('header').outerHeight();
    $('.p-attention .is-btn-close').on('click', function () {
      var attentionHeight = $(this).closest('.p-attention').outerHeight();
      eleTop = eleTop - attentionHeight;
    });
  }

  $(window).on('scroll', function () {
    let y = $(this).scrollTop();

    //header scroll
    if ($('.p-header').hasClass('p-home')) {
      if (y > 50) {
        $('.p-header').addClass('is-header-scroll');
        $('.is-menu-trigger').addClass('is-menu-trigger-scroll');
        $('header .p-h1').find('img').attr('src', imgPath + '/logo-black.svg');
        $('header .p-header-icon li').eq(0).find('img').attr('src', imgPath +'/icon-search-black.svg');
        $('header .p-header-icon li').eq(1).find('img').attr('src', imgPath + '/icon-login-black.svg');
        $('header .p-header-icon li').eq(2).find('img').attr('src', imgPath + '/icon-group-black.svg');
        if (!sp_window()) {
          $('.p-global').addClass('is-scroll-global');
        }
      } else {
        $('.p-header').removeClass('is-header-scroll');
        $('.is-menu-trigger').removeClass('is-menu-trigger-scroll');
        $('header .p-h1').find('img').attr('src', imgPath + '/logo-white.svg');
        $('header .p-header-icon li').eq(0).find('img').attr('src', imgPath + '/icon-search.svg');
        $('header .p-header-icon li').eq(1).find('img').attr('src', imgPath + '/icon-login.svg');
        $('header .p-header-icon li').eq(2).find('img').attr('src', imgPath + '/icon-group.svg');
        if (!sp_window()) {
          $('.p-global').removeClass('is-scroll-global');
        }
      }
    }
    //list page
    if ($('.is-list-scroll').length) {
      if (sp_window()) {
        if (eleTop < y + headerHeight) {
          ele.addClass('is-fixed');
          $('.p-list-detail').css('margin-top',eleHeight);
        } else {
          ele.removeClass('is-fixed');
          $('.p-list-detail').css('margin-top','0');
        }
      }
    }

    //if (1 <= y && y < 5) {}

  });
}

export function bodyfadeIn() {
  let $body = $('body');
  setTimeout(function () {
    $body.fadeIn(() => {
    });
  }, 800);
}